<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <div class="img-mobile-container"><img src="@/assets/images/Menu-icon-mobile.svg" alt="hamburger"></div>
    </button>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span
          class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item">
        <!-- <router-link :to="route.route">{{ route.name }}</router-link> -->
        <Dropdown v-for="(dropdown, index) in dropdownItems" :key="index" :mainText="dropdown.mainText"
          :dropdownItems="dropdown.items" :isOpen="activeIndex === index" @toggle-dropdown="toggleDropdown(index)" @select-item="closeMenu"/>
      </li>
    </ul>
  </div>
</template>

<script>
import Dropdown from '@/components/dropdown-mobile-header.vue'

export default {


  name: "MenuComponent",
  components: {
    Dropdown,
  },
  methods: {
    toggleDropdown(index) {
      if (this.activeIndex === index) {
        // Si ya está abierto, lo cerramos
        this.activeIndex = null;
      } else {
        // Si no está abierto, cerramos el menú desplegable abierto previamente (si lo hay) y abrimos el nuevo
        this.activeIndex = index;
      }
    },
    closeMenu() {
      this.isActive = false; // Cerrar el menú
    },
  },
 
  data() {
    return {
      isActive: false,
      activeIndex: null,
      dropdownItems:
       [
        {
          mainText: 'GAMING CENTER',
          items: [
            { text: 'Casino', route: 'casino' },
            { text: 'Racebook', route: 'racebook' },
            // Agregar más elementos si es necesario
          ]
        },
        {
          mainText: 'BETTING INFO',
          items: [
            { text: 'LIVE LINES', route: 'sportsbook' },
            { text: 'SPORTS RULES', route: 'sports-rules' },
            { text: 'HOUSE RULES', route: 'house-rules' },
            { text: 'HORSE RULES', route: 'horse-rules' },
            // Agregar más elementos si es necesario
          ]
        },
        {
          mainText: 'SPORTS BETTING  101',
          items: [
            { text: 'BETTING GUIDE', route: 'betting-guide' },
            { text: 'BETTING TYPES', route: 'sports-betting-types-parlay' },
            { text: 'HORSE RACING GUIDE', route: 'horse-racing-guide' },
            { text: 'HORSE RACING GLOSSARY', route: 'horse-racing-glossary' },
            // Agregar más elementos si es necesario
          ]
        },
        
        
        // Agregar más objetos dropdownItems según sea necesario
      ]
      //   routes: [
      //     {
      //       name       :  'Sportsbook',
      //       route      :  'sportsbook'
      //     },
      //     {
      //       name       :  'Racebook',
      //       route      :  'racebook'
      //     },
      //     {
      //       name       :  'Live Betting',
      //       route      :  'live-betting'
      //     },
      //     {
      //       name       :  'Props Builder',
      //       route      :  'props-builder'
      //     },
      //     {
      //       name       :  'Casino',
      //       route      :  'casino'
      //     },
      //   ]
      // };
    }
  },
  setup() {
      const agentClassic = "https://agents.betbigcity.ag";
      const agentNew = "https://adm.betbigcity.ag/ ";
      return {
        agentClassic,
        agentNew,
      }
    },
  };

</script>
