<template>
  <div id="go-up" class="go-up">anchor</div>
  <brand-header></brand-header>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <a href="/">
          <img class="header-content__img" src="@/assets/images/logo.png" alt="Beatthetotal" />
        </a>
      </div>
      <div class="header-content__info">
        <ul class="header-content__info--list">
          <li class="header-content__info--list-item">
            <div class="dropdown">
              <a @click="toggleDropdown(0)">GAMING CENTER</a>
              <ul v-if="isOpen[0]" class="items-container">
                <li @click="selectItem(0, 'Item0')" ><router-link to="casino">Casino</router-link></li>
                <li @click="selectItem(0, 'Item0')" ><router-link to="racebook">Racebook</router-link></li>
              </ul>
            </div>
            <!-- <Dropdown1>
            </Dropdown1> -->
          </li>
          <li class="header-content__info--list-item">
            <!-- Segundo dropdown -->
            <div class="dropdown">
              <a @click="toggleDropdown(1)">BETTING INFO</a>
              <ul v-if="isOpen[1]" class="items-container">
                <!-- Contenido del segundo dropdown -->
                <li @click="selectItem(1, 'Item1')" ><router-link to="sportsbook">LIVE LINES</router-link></li>
                <li @click="selectItem(1, 'Item1')" ><router-link to="sports-rules">SPORTS RULES</router-link></li>
                <li @click="selectItem(1, 'Item1')" ><router-link to="house-rules">HOUSE RULES</router-link></li>
                <li @click="selectItem(1, 'Item1')" ><router-link to="horse-rules">HORSE RULES</router-link></li>
              </ul>
            </div>
          </li>
          <li class="header-content__info--list-item">
            <!-- Tercer dropdown -->
            <div class="dropdown">
              <a @click="toggleDropdown(2)">SPORTS BETTING  101</a>
              <ul v-if="isOpen[2]" class="items-container">
                <!-- Contenido del Tercer dropdown -->
                <li @click="selectItem(2, 'Item2')" ><router-link to="betting-guide">BETTING GUIDE</router-link></li>
                <li @click="selectItem(2, 'Item2')" ><router-link to="sports-betting-types-parlay">BETTING TYPES</router-link></li>
                <li @click="selectItem(2, 'Item2')" ><router-link to="horse-racing-guide">HORSE RACING <br> GUIDE</router-link></li>
                <li @click="selectItem(2, 'Item2')" ><router-link to="horse-racing-glossary">HORSE RACING <br> GLOSSARY</router-link></li>
              </ul>
            </div>
          </li>
          <li class="header-content__info--list-item">
            <!-- Tercer dropdown -->
            <div class="dropdown">
              <a @click="toggleDropdown(3)">BETTING INFO</a>
              <ul v-if="isOpen[3]" class="items-container">
                <!-- Contenido del Tercer dropdown -->
                <li @click="selectItem(3, 'Item0')"><a target="_blank" :href="agentClassic">AGENT CLASSIC</a></li>
                <li @click="selectItem(3, 'Item0')"><a target="_blank" :href="agentNew">AGENT NEW</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="header-content__cta">
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <form class="form-login form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account">
            <input type="password" placeholder="Password" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
          </form>
          <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account">
            <input type="password" placeholder="Password" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
          </form>
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">BETSLIP</a>
        </div>
      </div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal.vue'
  import BrandHeader from '@/components/brand-header.vue'
  // import Dropdown1 from '@/components/dropdownHeader1.vue'
  // import Dropdown2 from '@/components/dropdownHeader2.vue'
  // import Dropdown3 from '@/components/dropdownHeader3.vue'
  // import Dropdown4 from '@/components/dropdownHeader4.vue'



  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
      BrandHeader,
      // Dropdown1,
      // Dropdown2,
      // Dropdown3,
      // Dropdown4


    },
    data() {
      return {
        isOpen: [] // Un array para almacenar el estado de cada dropdown
      };
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      },

      toggleDropdown(index) {
      // Si el dropdown ya está abierto, lo cerramos
        if (this.isOpen[index]) {
          this.isOpen[index] = false;
        } else {
          // Si se hizo clic en otro dropdown, cerramos todos los dropdowns
          for (let i = 0; i < this.isOpen.length; i++) {
            this.isOpen[i] = false;
          }
          // Abrimos el nuevo dropdown
          this.isOpen[index] = true;
        }
      },
      selectItem(index, item) {
        // Realizar la acción deseada al seleccionar un elemento del dropdown
        console.log(`Se seleccionó el item "${item}" del dropdown ${index + 1}`);
        
        // Cerrar el dropdown al seleccionar un elemento
        this.isOpen[index] = false;
      },

      created() {
        // Inicializamos el array isOpen con la misma cantidad de elementos que dropdowns
        // y establecemos todos los valores en false (todos los dropdowns cerrados)
        this.isOpen = Array(this.numeroDeDropdowns).fill(false);
      }


      
    },
    setup() {
      const DGS_SITEID = 2;
      const backendUrl = "betbigcity.ag";
      const agentClassic = "https://agents.betbigcity.ag";
      const agentNew = "https://adm.betbigcity.ag/ ";
      return {
        DGS_SITEID,
        backendUrl,
        agentClassic,
        agentNew,
      }
    },
  };
</script>
