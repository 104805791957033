<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
      <h3>User login</h3>
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    
    <div class="modal-content" v-if="type === 'classic'">
      <!-- Contenido de tu modal aquí -->
      <form class="login__form form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/>
            <div class="login__form--item">
              <input class="login__form--input" type="text" placeholder="User" name="account" id="account">
            </div>
            <div class="login__form--item">
              <input class="login__form--input" type="password" placeholder="Password" name="password" id="password">
            </div>
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
          </form>
          
          <form class="login__form form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/>
            <div class="login__form--item">
              <input class="login__form--input" type="text" placeholder="User" name="account" id="account">
            </div>
            <div class="login__form--item">
              <input class="login__form--input" type="password" placeholder="Password" name="password" id="password">
            </div>
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
      </form>
    </div>
    <div class="modal-content" v-else>
      <!-- Contenido de tu modal aquí -->
      <form class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
        <div class="login__form--item">
          <label class="login__form--label" for="username">Username</label>
          <input class="login__form--input" name="username" id="username" type="text" required placeholder="Username"/>
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="password">Password</label>
          <input class="login__form--input" type="password" name="password" id="password" required placeholder="Password"/>
        </div>
        
        <div class="text-center mt-2"> 
          <span name="msj_loading" id="id-login-loading"></span>
          <small class="text-danger" name="msj_error_lg"></small>
        </div>

        
        <div class="login__btns-login">
          <input type="submit" class="login-button" name="send" id="send" value="login">
          <input type="hidden" name="BackEndUrl" :value="`https://betslip.${backendUrl}`">
        </div>

        <div class="login__link">
          <!-- <router-link to="/forgot-password" @click="closeModal">Forgot password ?</router-link> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalComponent',
    props:{
      type:String
    },
    setup:() => {
      const DGS_SITEID = 2;
      const backendUrl = "betbigcity.ag";
      return {
        backendUrl,
        DGS_SITEID
      }
    },
    data() {
      return {
        showModal: false
      }
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  }
</script>