<template>
   <a class="dropdown-mobile">
    <a class="tittle" @click="toggleDropdown">{{ mainText }}</a>
    <ul class="items-container-mobile" v-show="isOpen" ref="dropdownContent">
      <li @mouseleave="toggleDropdown" v-for="(item, index) in dropdownItems" :key="index" @click="selectItem(index)">
            <router-link class="items" :to="item.route">{{ item.text }}</router-link>
      </li>
    </ul>
  </a>
</template>

<script>
export default  {
  data() {
    return {
      isActive: true,
    }
  },
  props: {
    mainText: {
      type: String,
      required: true
    },
    dropdownItems: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleDropdown() {
      this.$emit("toggle-dropdown");
    },
    selectItem(index) {
      this.selectedIndex = index;
      this.$emit('select-item', index); // Emitimos el evento con el índice del elemento seleccionado
    },
  }
};
</script>