<template>
  <footer class="footer">
    <a href="#go-up" class="go-to-up"><img src="@/assets/images/icon-up.svg" alt="go-up"></a>
    <div class="footer-content">
      <section class="agent-buttons">
        <div class="img-container">
          <img src="@/assets/images/logo.png" alt="BeatTheTotal" width="auto" height="auto">
        </div>
        <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agents classic</a>
        <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agents new</a>
      </section>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes"
          :key="index">
          <div class="report" v-if="route.class === 'dropdown'">
            <Dropdown></Dropdown>
          </div>
          <div v-else>
            <router-link :to="route.route">{{ route.name }}</router-link>
          </div>
        </li>
      </ul>
    </div>
    <section class="footer__copyright">
      <p>Copyrights © 2023 All Rights Reserved by Betbigcity.ag </p>
    </section>
  </footer>
  <!-- <div class="footer-copyright">
    Wager Your Mojo © 2023 TERMS AND CONDITIONS
  </div> -->
</template>

<script>
import Dropdown from '@/components/dropdownFooter.vue'

export default {
  name: "FooterComponent",
  components: {
    Dropdown
  },
  setup() {
    const agentClassic = "https://agents.betbigcity.ag";
    const agentNew = "https://adm.betbigcity.ag/ ";
    return {
      agentClassic,
      agentNew,
      routes: [
        {
          class: '',
          name: 'Responsible Gambling',
          route: 'responsible-gambling'
        },
        // {
        //   class: '',
        //   name: 'Contact Us',
        //   route: 'contact-us'
        // },
        {
          class: '',
          name: 'Privacy Policy',
          route: 'privacy-policy'
        },
        
        {
          class: '',
          name: 'Terms & Conditions',
          route: 'terms-and-conditions'
        },
        {
          class: '',
          route      :  'same-game-parlay-rules',
          name        : 'same game parlay rules'
        },
        {
          class: 'dropdown',
          name: 'Reports',

        },
      ]
    }
  },

};
</script>
